import React, { useCallback, useContext } from "react";
import cn from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MAIN_TAB_INDEX } from "../../../constants";
import { isMobile, isTabletView } from "../../../util";
import { SaleService } from "@/services";
import { isDateInPast } from "../../../util/common";
import ContentImage from "../../content/contentImage";
import SaleTabContent from "./SaleTabContent";
import { SaleMobileTimer } from "./SaleMobileTimer";
import { SalePersentageBlock } from "./SalePersentageBlock";
import { SaleProductPriceBlock } from "./SaleProductPriceBlock";
import SaleWebFilteringSection from "./saleFilters/saleWebFilteringSection";
import FlashSaleFiltersMob from "./saleFilters/saleFiltersMob";
import { SaleWishList } from "./SaleWishList";
import { SaleComingSoon } from "./SaleComingSoon";
import SaleWishlistButton from "./SaleWishlistButton";
import ProductSizes from "@/routes/productListing/sizes";
import { useIsRTL, useRouteSlug } from "@/hooks";
import useIsSmallScreen from "@/hooks/useIsSmallScreen";
import { SalePageDataContext } from "./SaleWrapper";
import ProductOverlayTag from "@/components/product/productOverlayTag";

const SaleTabs = ({
  saleDefaultData,
  configCdnImagesSettings,
  currentTab,
  tabsData,
  language,
  onFilterClick,
  selectedAssistiveFilters,
  setSelectedAssistiveFilters,
  handleTabClick
}) => {
  const { isSmallScreen } = useIsSmallScreen();
  const routeSlug = useRouteSlug();
  const { saleData, setSelectedFiltersQuery, setSaleData, pageData } =
    useContext(SalePageDataContext);
  const products = saleDefaultData.hits || null;
  const tabDetails = tabsData[currentTab - 1];
  const isMainTab = currentTab === MAIN_TAB_INDEX;
  const isRTL = useIsRTL();
  const { t } = useTranslation("sale");
  if (!tabDetails) return null;

  const bannerData = pageData.data;
  const pageContentValue = bannerData.slug;
  // const { assistiveFilter } = saleDefaultData;
  // const assistiveFilterKey = assistiveFilter || "hierarchicalCategories.lvl0";
  // const assistiveFiltersText = products?.length
  //   ? Object.keys(saleDefaultData.facets[assistiveFilterKey] || {})
  //   : [];

  const showMobileTimer = Boolean(isSmallScreen && tabDetails?.productsCount);

  // const onFilterClickHandler = filter => {
  //   const updatedSelectedFilters = {
  //     ...selectedAssistiveFilters,
  //     [assistiveFilterKey]: filter
  //   };
  //   setSelectedAssistiveFilters(updatedSelectedFilters);

  //   onFilterClick({ slug: tabDetails.slug, queries: updatedSelectedFilters });
  // };

  const resetFilters = useCallback(async () => {
    setSelectedFiltersQuery({});
    const { data } = await SaleService.getSaleSpecificTabData(
      tabDetails.slug,
      pageContentValue,
      {}
    );
    setSaleData(data);
  }, [tabDetails]);

  return (
    <div className="tabs-container">
      {isSmallScreen && <h3 className="mobile-heading">{tabDetails?.title}</h3>}
      <div className="tabs">
        {tabsData.map(
          (tab, i) =>
            tab.enabled && (
              <button
                key={tab.id}
                id={tab.id}
                disabled={currentTab === tab.id}
                onClick={handleTabClick}
                className="tabs-button"
              >
                <SaleTabContent
                  data={tab}
                  isFlashImage={tab.mainTab}
                  dateTo={tab.to}
                  dateFrom={tab.from}
                  productsLength={tab.productsCount}
                />
              </button>
            )
        )}
      </div>
      {!isSmallScreen && (
        <>
          <div className="page-title">
            <h2>
              {tabDetails?.title}
              {saleData?.nbHits && (
                <span>
                  ({saleData?.nbHits} {t("items")})
                </span>
              )}
            </h2>
          </div>
          <SaleWebFilteringSection tabDetails={tabDetails} key={tabDetails} />
          {/* {Boolean(assistiveFiltersText.length) && (
            <div className="assistive-filters flex-wrap container">
              {assistiveFiltersText.map((filter, index) => (
                <span key={index} onClick={() => onFilterClickHandler(filter)}>
                  {filter.split(">").pop()}
                </span>
              ))}
            </div>
          )} */}
        </>
      )}
      {showMobileTimer && (
        <SaleMobileTimer dateTo={tabDetails?.to} dateFrom={tabDetails?.from} />
      )}
      {isSmallScreen && (
        <FlashSaleFiltersMob tabDetails={tabDetails} key={tabDetails} />
      )}
      {/* TODO: Update this part after Flash sale page start working */}

      {/* {isMobileView &&
        Boolean(assistiveFiltersText.length) &&
        (() => {
          let MobileAssistiveFilterWrapper = Swiper;
          let mobileAssistiveFilterWrapperProps = {
            rtl: language.includes("ar") ? "rtl" : "",
            ...swiperParams
          };

          if (assistiveFiltersText.length <= UNSWIPEABLE_COUNT) {
            MobileAssistiveFilterWrapper = Fragment;
            mobileAssistiveFilterWrapperProps = {};
          }

          return (
            <div className="assistive-filters">
              <MobileAssistiveFilterWrapper
                {...mobileAssistiveFilterWrapperProps}
              >
                {assistiveFiltersText.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() => onFilterClickHandler(filter)}
                  >
                    {filter.split(">").pop()}
                  </span>
                ))}
              </MobileAssistiveFilterWrapper>
            </div>
          );
        })()} */}
      <div
        className={cn("content container large-container", {
          arabic: isRTL
        })}
      >
        {tabsData.map((tab, i) => {
          const isSaleAlreadyStarted = isDateInPast(tab.from);
          return (
            <div key={i}>
              {currentTab === tab.id && (
                <div className="products-wrapper product_listing_wrap flash_sale_products">
                  {tabDetails.productsCount || products !== null ? (
                    products.length ? (
                      products.map(product => {
                        return (
                          <div
                            key={product.id}
                            className={cn("product", {
                              "hover_view isMobileDevice":
                                isSmallScreen || isTabletView()
                            })}
                          >
                            <Link
                              to={{
                                pathname: `/${routeSlug}/${product.url}`
                              }}
                            >
                              <div className="product-image">
                                <div
                                  className={cn({
                                    "unavailable-product": !product?.pstock
                                  })}
                                >
                                  <ContentImage
                                    img={product.captionImageURL}
                                    url={configCdnImagesSettings?.list}
                                    showDefaultImage
                                  />
                                </div>
                                <SalePersentageBlock product={product} />

                                <ProductOverlayTag
                                  overlayLabel={product.overlayTagLabel?.label}
                                  overlayTagBgColor={
                                    product.overlayTagLabel?.style
                                      ?.backgroundColor
                                  }
                                  overlayTagLabelColor={
                                    product.overlayTagLabel?.style?.textColor
                                  }
                                />

                                {(isSaleAlreadyStarted || isSmallScreen) && (
                                  <SaleWishList product={product} />
                                )}

                                {!product?.pstock && (
                                  <p className="out-of-stock">{t("soldOut")}</p>
                                )}
                              </div>
                              <div
                                className="product-sizes"
                                onClick={e => {
                                  e.preventDefault();
                                }}
                              >
                                <div
                                  className={cn("product-size-wrapper", {
                                    "unavailable-product": !product?.pstock
                                  })}
                                >
                                  <ProductSizes
                                    sizes={product.displaySizes}
                                    gridSize={5}
                                  />
                                </div>
                              </div>
                              <h3 className="product-title">
                                {product.brand?.label}
                              </h3>
                              <p className="product-description">
                                {product.title?.label}
                              </p>

                              <SaleProductPriceBlock
                                product={product}
                                isMainTab={isMainTab}
                                dateFrom={tabDetails?.from}
                              />
                            </Link>
                            {!isSaleAlreadyStarted && !isSmallScreen && (
                              <SaleWishlistButton
                                product={product}
                                addToTranslation={t("addToWishlist")}
                              />
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="reset-filter noRecordForListing">
                        <button
                          className="round_btn form_black_btn btn"
                          onClick={resetFilters}
                        >
                          {t("resetFilter")}
                        </button>
                      </div>
                    )
                  ) : (
                    <SaleComingSoon />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SaleTabs;
